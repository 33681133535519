<template>
    <div v-if="Object.keys(labels).length" class="space-reviews client-content-padding">
        <div class="row">
            <div v-for="(review, index) in data.reviews" :key="`space-review-${index}`" class="col-12 col-md-6">
                <div class="space-review">
                    <h6 class="title">
                        {{ review.title }}
                    </h6>
                    <span v-html="review.description"></span>
                    <p class="name">
                        {{ review.name }}
                    </p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'SpaceReviews',
    props: {
        labels: {
            type: Object,
            default: () => {
                return {}
            }
        },
        data: {
            type: Object,
            default: () => {
                return {}
            }
        }
    },
    data() {
        return {}
    }
}
</script>

<style scoped lang="scss">
    @import '../styles/spaces_common.scss';
    
    .space {
        &-reviews {
            margin-top: -30px;

            div[class*="col-"] {
                &:nth-of-type(even) {
                    .space-review {
                        background-color: $focus_color;
                        color: $color_over_focus;

                        ::v-deep {
                            * {
                                color: $color_over_focus;
                            }
                        }
                    }
                }
            }
        }
        
        &-review {
            border-radius: $border_radius;
            padding: 15px;
            box-shadow: $box_shadow;
            background-color: $color_over_focus;
            margin-top: 30px;

            p.name {
                margin-bottom: 0px;
                margin-top: 1rem;
                text-align: right;
                font-style: italic;
                opacity: 0.5;
            }
        }
    }
</style>